<template>
  <v-container>
    <vue-snotify></vue-snotify>
    <v-card>
      <v-card-title class="cyan darken-1">
        <span class="white--text">Crear plan</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <br />

          <v-row>
            <v-col cols="12" lg="6">
              <v-select
                v-model="select"
                :items="services"
                item-text="name"
                item-value="id"
                prepend-icon="mdi-view-list"
                label="Seleccionar servicio *"
                persistent-hint
                return-object
              ></v-select>
            </v-col>
            <v-col cols="12" lg="6">
              <v-text-field
                v-model="name"
                :rules="nameRules"
                :counter="190"
                prepend-icon="mdi-octagram"
                label="Nombre *"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="price"
                :rules="priceRules"
                :counter="10"
                prefix="$"
                prepend-icon="mdi-cash-multiple"
                label="Precio *"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="description"
                :rules="descriptionRules"
                :counter="190"
                prepend-icon="mdi-format-list-group"
                label="Descripción"
              ></v-text-field>
            </v-col>
          </v-row>
          <br /><br />
          <v-divider></v-divider> <br />
          <v-row>
            <v-col cols="12" lg="9"></v-col>
            <v-col cols="12" lg="3">
              <v-btn color="default" class="mr-4" @click="cancel">
                Cancelar
              </v-btn>
              <v-btn color="primary" @click="create"> Crear </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data: () => ({
    services: [],
    name: "",
    description: "",
    price: "",

    select: "",

    descriptionRules: [
      (v) =>
        (v && v.length <= 190) || "El campo no debe exceder de 190 caracteres",
    ],
    nameRules: [
      (v) =>
        (v && v.length <= 190) || "El campo no debe exceder de 190 caracteres",
    ],

    priceRules: [
      (v) =>
        (v && v.length <= 10) || "El campo no debe exceder de 10 caracteres",
    ],
  }),

  mounted() {
    this.getServices();
  },
  methods: {
    cancel() {
      setTimeout(() => this.$router.push({ name: "service" }), 10);
    },

    create() {
      console.log("entra");

      let request = {
        name: this.name,
        description: this.description,
        price: this.price,
        id_service: this.select.id,
      };

      console.log(request);
      if (this.select != "") {
        axios
          .post("/save-plan", request)
          .then((response) => {
            this.displayNotification("success", "Éxito", response.data);
            console.log(response.data);
            setTimeout(() => this.$router.push({ name: "plan" }), 4000);
          })
          .catch((err) => {
            console.log(err, "error");
            this.displayNotification("error", "Error", err);
            setTimeout(() => this.$router.push({ name: "plan" }), 4000);
          });
      } else {
        this.displayNotification(
          "error",
          "Error",
          "Debe seleccionar un servicio"
        );
      }
    },

    async getServices() {
      axios
        .get("/services")
        .then((response) => {
          console.log(response.data);
          this.services = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al obtener los servicios");
        });
    },
  },
};
</script>